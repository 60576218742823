// Define the document styles for a DOCX file
export const docStyles = {
    default: {
        heading1: {
            run: {
                font: 'Calibri',
                size: 48,
                bold: true,
                color: '000000',
            },
        },
        heading2: {
            run: {
                font: 'Calibri',
                size: 36,
                bold: true,
                color: '000000',
            },
        },
        heading3: {
            run: {
                font: 'Calibri',
                size: 30,
                bold: true,
                color: '000000',
            },
        },
        paragraph: {
            run: {
                font: 'Calibri',
                size: 24,
                color: '444444',
            },
        },
    },
    paragraphStyles: [
        {
            id: 'Heading1',
            name: 'Heading 1',
            basedOn: 'Normal',
            next: 'Normal',
            run: {
                font: 'Calibri',
                size: 48,
                bold: true,
                color: '000000',
            },
        },
        {
            id: 'Heading2',
            name: 'Heading 2',
            basedOn: 'Normal',
            next: 'Normal',
            run: {
                font: 'Calibri',
                size: 36,
                bold: true,
                color: '000000',
            },
        },
        {
            id: 'Heading3',
            name: 'Heading 3',
            basedOn: 'Normal',
            next: 'Normal',
            run: {
                font: 'Calibri',
                size: 30,
                bold: true,
                color: '000000',
            },
        },
        {
            id: 'BodyText',
            name: 'Body Text',
            basedOn: 'Normal',
            next: 'Normal',
            run: {
                font: 'Calibri',
                size: 24,
                color: '444444',
            },
        },
        {
            id: 'CodeBlock',
            name: 'Code Block',
            basedOn: 'Normal',
            next: 'Normal',
            run: {
                font: 'Courier New',
                size: 22,
                color: '333333',
                italics: true,
            },
        },
        {
            id: 'BlockQuote',
            name: 'Block Quote',
            basedOn: 'Normal',
            next: 'Normal',
            run: {
                font: 'Calibri',
                size: 24,
                color: '666666',
                italics: true,
            },
        },
        {
            id: 'ListItem',
            name: 'List Item',
            basedOn: 'Normal',
            next: 'Normal',
            run: {
                font: 'Calibri',
                size: 24,
                color: '444444',
            },
        },
    ],
};

// src/App.js
// Main application component that renders the Middleware component.

import React from 'react';
import Middleware from './components/middleware';

function App() {
  return (
    <div className="App">
      <Middleware />
    </div>
  );
}

export default App;

import React, { useState, useEffect, useRef } from 'react';
import './chatInterface.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faImage, faThumbsDown, faSyncAlt, faXmark, faArrowUp, faTrash, faMicrophone, faStop, faDownload } from '@fortawesome/free-solid-svg-icons';
import Message from './message';
import { useVoiceInput } from './useVoiceInput';
import LikeFeedback from './likeFeedback';
import DislikeFeedback from './dislikeFeedback';
import { downloadDocx } from './downloadDocx';

function ChatInterface({ messages, inputValue, setInputValue, sendMessage, recommendGraph, refreshThread, resubmitMessage, changeModel }) {
    const [messagesEmpty, setMessagesEmpty] = useState(true);
    const [showLikeFeedback, setShowLikeFeedback] = useState(false);
    const [showDislikeFeedback, setShowDislikeFeedback] = useState(false);
    const chatContainerRef = useRef(null);
    const [selectedModel, setSelectedModel] = useState("Glue-Model");
    const [isAutoScrolling, setIsAutoScrolling] = useState(true);

    const clearInput = () => { setInputValue(''); };
    const clearPage = () => {
        refreshThread();
        setMessagesEmpty(true);
    };

    const handleSendMessage = () => {
        setMessagesEmpty(false);
        sendMessage();
        stopRecognition();
    };

    const handleLike = () => {
        setShowLikeFeedback(true);
    };

    const handleDislike = () => {
        setShowDislikeFeedback(true);
    };

    const handleCloseFeedback = () => {
        setShowLikeFeedback(false);
        setShowDislikeFeedback(false);
    };

    const { handleVoiceInput, isRecording, stopRecognition } = useVoiceInput(setInputValue, handleSendMessage);

    const selectModel = (model) => {
        if (!messagesEmpty) {
            refreshThread()
        }
        setSelectedModel(model)
        changeModel(model)
    }

    useEffect(() => {
        const handleScroll = () => {
            if (chatContainerRef.current) {
                const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current;
                // Stop auto-scrolling if the user scrolls up
                setIsAutoScrolling(scrollTop + clientHeight >= scrollHeight - 10);
            }
        };

        const chatContainer = chatContainerRef.current;
        if (chatContainer) {
            chatContainer.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (chatContainer) {
                chatContainer.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    useEffect(() => {
        if (isAutoScrolling && chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [messages, isAutoScrolling]);

    return (
        <div>
            <div>
                <img className="glueLogo" alt="" />
                {!messagesEmpty ? (
                    <img className="gptLogo" alt="" />
                ) : null}
            </div>
            <div className={"container"}>
                <div className={"backgroundLogo" + (!messagesEmpty ? " hidden" : "")}>
                    <>
                        <img className="gptBG" alt="" />
                        <img className="glueBG" alt="" />
                    </>
                </div>
                <div className={"response" + (messagesEmpty ? " hidden" : "")} ref={chatContainerRef}>
                    <div id="chat-bot">
                        <div className="refresh">
                            {!messagesEmpty ? (
                                <button id="refresh-btn" onClick={clearPage} title="Clear Page">
                                    <FontAwesomeIcon icon={faXmark} />
                                </button>
                            ) : null}
                        </div>
                        {messages.map((msg, index) => (
                            <Message key={index} msg={msg} />
                        ))}
                        {messages.length > 0 && !messages[messages.length - 1].isStreaming ? (
                            <div className="functionalities">
                                <button id="resubmit-btn" onClick={resubmitMessage}>
                                    <FontAwesomeIcon icon={faSyncAlt} />
                                    <span className="tooltip">Re-submit</span>
                                </button>
                                <button id="view-picture-btn" onClick={recommendGraph} >
                                    <FontAwesomeIcon icon={faImage} />
                                    <span className="tooltip">Best Diagram</span>
                                </button>
                                <button id="like-btn" onClick={handleLike}>
                                    <FontAwesomeIcon icon={faThumbsUp} />
                                    <span className="tooltip">Like</span>
                                </button>
                                <button id="dislike-btn" onClick={handleDislike}>
                                    <FontAwesomeIcon icon={faThumbsDown} />
                                    <span className="tooltip">Dislike</span>
                                </button>
                                <button id="download-btn" onClick={() => downloadDocx(messages)}>
                                    <FontAwesomeIcon icon={faDownload} />
                                    <span className="tooltip">Download</span>
                                </button>
                            </div>
                        ) : null}
                    </div>
                </div>

                <div className="input-area">
                    <div className={"dropdown" + (!messagesEmpty ? " hidden" : "")}>
                    <div className={`select-box ${(messages.length > 0 && messages[messages.length - 1].isStreaming) ? 'disabled' : ''}`} tabIndex="0">
                        <div className="selected">{selectedModel}</div>
                        <div className="options-container">
                            <button className="option" onClick={() => selectModel("Glue-Model")}>Glue Model</button>
                            <button className="option" onClick={() => selectModel("GPT-4o")}>GPT-4o</button>
                            <button className="option" onClick={() => selectModel("GPT-4 Turbo")}>GPT-4 Turbo</button>
                            <button className="option" onClick={() => selectModel("GPT-4")}>GPT-4</button>
                            <button className="option" onClick={() => selectModel("GPT-3.5")}>GPT 3.5</button>
                        </div>
                    </div>
                    </div>
                    <textarea
                        id="chat-input"
                        placeholder="Hi I'm Glue GPT, how can I help you today?"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                handleSendMessage();
                            }
                        }}
                        disabled={messages.length > 0 && messages[messages.length - 1].isStreaming}
                    />
                    <div className="buttons">
                        <button id="voice-btn" onClick={handleVoiceInput} title={isRecording ? "Stop Recording" : "Start Recording"}>
                            <FontAwesomeIcon icon={isRecording ? faStop : faMicrophone} />
                        </button>
                        <button id="clear-btn" onClick={clearInput} disabled={!inputValue.trim().length} title="Clear Input"><FontAwesomeIcon icon={faTrash} />
                        </button>
                        <button id="send-btn" onClick={handleSendMessage} disabled={!inputValue.trim().length} title="Send"><FontAwesomeIcon icon={faArrowUp} />
                        </button>
                    </div>
                </div>

                {showLikeFeedback && <LikeFeedback onClose={handleCloseFeedback} />}
                {showDislikeFeedback && <DislikeFeedback onClose={handleCloseFeedback} />}
            </div>
        </div>
    );
}

export default ChatInterface;

// Middleware.jsx
// This component connects the chat interface with the chat logic.

import React from 'react';
import ChatInterface from './chatInterface';
import useChat from '../hooks/useChat';

function Middleware() {
    const { inputValue, setInputValue, messages, sendMessage, recommendGraph, refreshThread, resubmitMessage, changeModel } = useChat();

    return (
        <ChatInterface
            messages={messages}
            inputValue={inputValue}
            setInputValue={setInputValue}
            sendMessage={sendMessage}
            recommendGraph={recommendGraph}
            refreshThread={refreshThread}
            resubmitMessage={resubmitMessage}
            changeModel={changeModel}
        />
    );
}

export default Middleware;

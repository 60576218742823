import { Document, Packer, Paragraph, TextRun, ImageRun } from 'docx';
import { saveAs } from 'file-saver';
import { v4 as uuidv4 } from 'uuid';
import { marked } from 'marked';
import { docStyles } from './docxStyles';
import axios from 'axios';


const fetchImageAsBase64 = async (url) => {
    try {
        console.log(`Fetching image from URL: ${url}`);
        const response = await axios.get(url, { responseType: 'blob' });
        const blob = response.data;
        return await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result.split(',')[1]);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    } catch (error) {
        console.error('Error fetching image:', error);
        return null;
    }
};

const getImageDimensions = (base64Image) => {
    return new Promise((resolve) => {
        const img = new Image();
        img.onload = () => {
            resolve({ width: img.width, height: img.height });
        };
        img.src = `data:image/png;base64,${base64Image}`;
    });
};

const imageCache = {};

const parseMarkdownToDocx = async (markdown) => {
    const html = marked(markdown);
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    const convertNodeToParagraph = async (node) => {
        let textRuns = [];

        for (const child of node.childNodes) {
            if (child.nodeType === Node.TEXT_NODE) {
                if (child.textContent.trim()) { // Only add non-empty text nodes
                    console.log(`Text node: ${child.textContent}`);
                    textRuns.push(new TextRun({ text: child.textContent, break: child.textContent.includes('\n') }));
                }
            } else if (child.nodeType === Node.ELEMENT_NODE) {
                console.log(`Element node: ${child.tagName}`);
                switch (child.tagName.toLowerCase()) {
                    case 'strong':
                        textRuns.push(new TextRun({ text: child.textContent, bold: true }));
                        break;
                    case 'em':
                        textRuns.push(new TextRun({ text: child.textContent, italics: true }));
                        break;
                    case 'u':
                        textRuns.push(new TextRun({ text: child.textContent, underline: {} }));
                        break;
                    case 'code':
                        textRuns.push(new TextRun({ text: child.textContent, font: 'Courier New' }));
                        break;
                    case 'br':
                        textRuns.push(new TextRun({ text: '\n' }));
                        break;
                    case 'a':
                        textRuns.push(new TextRun({ text: child.textContent, color: '0000FF', underline: {} }));
                        break;
                    case 'pre':
                        textRuns.push(new TextRun({ text: '', break: 1 }));
                        const codeBlock = child.textContent;
                        const codeLines = codeBlock.split('\n');
                        for (const line of codeLines) {
                            textRuns.push(new TextRun({
                                text: line,
                                font: 'Courier New',
                                size: 22,
                                color: '333333',
                                break: 1,
                                preserveWhitespace: true,
                            }));
                        }
                        break;
                    case 'img':
                        const src = child.getAttribute('src');
                        if (src && src.startsWith('http')) {
                            console.log(`Processing image with src: ${src}`);
                            let imageData = imageCache[src];
                            if (!imageData) {
                                imageData = await fetchImageAsBase64(src);
                                if (imageData) {
                                    imageCache[src] = imageData;
                                } else {
                                    console.log(`Failed to fetch image for src: ${src}`);
                                }
                            }
                            if (imageData) {
                                const { width, height } = await getImageDimensions(imageData);
                                const maxWidth = 600;
                                const maxHeight = 300;
                                let newWidth = width;
                                let newHeight = height;

                                if (width > maxWidth || height > maxHeight) {
                                    const widthRatio = maxWidth / width;
                                    const heightRatio = maxHeight / height;
                                    const ratio = Math.min(widthRatio, heightRatio);

                                    newWidth = width * ratio;
                                    newHeight = height * ratio;
                                }

                                console.log(`Adding image to document with dimensions: ${newWidth}x${newHeight}`);
                                textRuns.push(new ImageRun({
                                    data: Uint8Array.from(atob(imageData), c => c.charCodeAt(0)),
                                    transformation: {
                                        width: newWidth,
                                        height: newHeight,
                                    },
                                }));
                            }
                        }
                        break;
                    default:
                        if (child.textContent.trim()) { // Only add non-empty text elements
                            textRuns.push(new TextRun({ text: child.textContent }));
                        }
                        break;
                }
            }
        }

        return new Paragraph({
            children: textRuns,
            style: 'BodyText',
        });
    };

    const convertElementToParagraph = async (element) => {
        if (!element.tagName) {
            return await convertNodeToParagraph(element);
        }
        switch (element.tagName.toLowerCase()) {
            case 'h1':
                return new Paragraph({
                    children: [new TextRun({ text: element.textContent, bold: true, size: 48 })],
                    style: 'Heading1',
                });
            case 'h2':
                return new Paragraph({
                    children: [new TextRun({ text: element.textContent, bold: true, size: 36 })],
                    style: 'Heading2',
                });
            case 'h3':
                return new Paragraph({
                    children: [new TextRun({ text: element.textContent, bold: true, size: 30 })],
                    style: 'Heading3',
                });
            case 'blockquote':
                return new Paragraph({
                    children: [new TextRun({ text: element.textContent, italics: true, color: '666666' })],
                    style: 'BlockQuote',
                });
            case 'li':
                return new Paragraph({
                    children: [new TextRun({ text: `• ${element.textContent}`, size: 24 })],
                    style: 'ListItem',
                });
            case 'pre':
                return new Paragraph({
                    children: [
                        new TextRun({ text: '', break: 1 }),
                        ...element.textContent.split('\n').map(line => 
                            new TextRun({
                                text: line,
                                font: 'Courier New',
                                size: 22,
                                color: '333333',
                                break: 1,
                                preserveWhitespace: true,
                            })
                        )
                    ],
                    style: 'CodeBlock',
                });
            default:
                return await convertNodeToParagraph(element);
        }
    };

    const paragraphs = await Promise.all(
        Array.from(doc.body.childNodes)
            .filter(node => node.nodeType === Node.ELEMENT_NODE || node.nodeType === Node.TEXT_NODE)
            .map(async (node) => await convertElementToParagraph(node))
    );

    return paragraphs;
};

export const downloadDocx = async (messages) => {
    const docId = uuidv4(); // Generate a UUID for the document

    const doc = new Document({
        styles: docStyles,  // Use imported styles
        sections: [{
            properties: {},
            children: [
                new Paragraph({
                    text: `Conversation with Glue GPT`,
                    style: 'Heading1',
                }),
                ...(await Promise.all(messages.map(async (msg) => {
                    const sender = msg.sender === 'User' ? 'YOU' : 'Glue GPT';
                    const content = `**${sender}**: ${msg.content}`;
                    console.log(`Processing message: ${content}`);
                    return await parseMarkdownToDocx(content);
                }))).flat()  // Flatten the array since parseMarkdownToDocx returns an array
            ],
        }],
    });

    Packer.toBlob(doc)
        .then(blob => { 
            saveAs(blob, `conversation-${docId}.docx`); // Include UUID in file name
        })
        .catch(err => {
            console.error("Error generating document:", err);
        });
};

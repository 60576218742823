// useChat.js
// This custom hook manages the state and logic for the chat interface.

import { useState } from 'react';
import { flushSync } from 'react-dom';

function useChat() {
    const [inputValue, setInputValue] = useState('');
    const [messages, setMessages] = useState([]);
    const API_URL = process.env.REACT_APP_API_URL;

    const handleMessage = async (userMessage) => {
        try {
            await fetch(`${API_URL}/chat`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ message: userMessage }),
            })
            .then(async res => {
                setMessages(prevMessages => [
                    ...prevMessages.slice(0, -1),
                    { sender: 'GLUEGPT', content: '', isStreaming: true }
                ]);

                const decoder = new TextDecoder('utf-8', { stream: true });

                let partialWord = ''; // Variable to store any incomplete word from the previous chunk

                for await (const chunk of res.body) {
                    const text = decoder.decode(chunk);
                    console.log(`chunk: ${text}`);

                    // Combine any partial word from the previous chunk with the current chunk text
                    const combinedText = partialWord + text;
                    const words = combinedText.split(' ');

                    // The last word might be incomplete, save it for the next chunk
                    partialWord = words.pop();

                    for (const word of words) {
                        flushSync(() => {
                            setMessages(prevMessages => {
                                const updatedMessages = [...prevMessages];
                                updatedMessages[updatedMessages.length - 1].content += ' ' + word;
                                return updatedMessages;
                            });
                        });
                    }
                }

                // If there's any remaining partial word, add it to the message
                if (partialWord) {
                    flushSync(() => {
                        setMessages(prevMessages => {
                            const updatedMessages = [...prevMessages];
                            //updatedMessages[updatedMessages.length - 1].content += ' ' + partialWord;
                            updatedMessages[updatedMessages.length - 1].content += ' ' + partialWord;
                            updatedMessages[updatedMessages.length - 1].isStreaming = false; // Set isStreaming to false when streaming is complete
                            return updatedMessages;
                        });
                    });
                }
                });
                // const data = await response.json();
                // setMessages(prevMessages => [...prevMessages.slice(0, -1), { sender: 'GLUEGPT', content: data.reply}]);
            } catch (error) {
                console.error('Error sending message:', error);
            }

    }

    const sendMessage = async () => {
        if (!inputValue.trim()) return;
        const userMessage = inputValue;
        setInputValue('');
        setMessages(prevMessages => [...prevMessages, { sender: 'User', content: userMessage, isStreaming: false }]);
        setMessages(prevMessages => [...prevMessages, { sender: 'System', content: 'Processing Request...', isStreaming: true }]);

        handleMessage(userMessage)
        
    };

    const recommendGraph = async () => {
        const userMessage = "Please recommend the best diagram type for the system you've previously described";
        setInputValue('');
        setMessages(prevMessages => [...prevMessages, { sender: 'System', content: 'Recommending best graph...', isStreaming: true }]);

        handleMessage(userMessage)
    };

    const refreshThread = async () => {
        setMessages([]);
        try {
            //await fetch(`${API_URL}/refresh-thread`, { method: 'POST' });
            await fetch(`${API_URL}/refresh-thread`, {method: 'POST'})
        } catch (error) {
            console.error('Error refreshing thread:', error);
        }
    };

    const changeModel = async (model) => {
        try {
            //await fetch(`${API_URL}/refresh-thread`, { method: 'POST' });
            await fetch(`${API_URL}/change-model`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ 'model': model }),
            })
        } catch (error) {
            console.error('Error changing model:', error);
        }
    }

    const resubmitMessage = async () => {
        const userMessages = messages.filter(msg => msg.sender === 'User');
        const lastUserMessage = userMessages[userMessages.length - 1];
        const userMessage = `Please regenrate your response for the previous message: ${lastUserMessage.content}`;
        setInputValue('');

        setMessages(prevMessages => [...prevMessages, { sender: 'System', content: 'Regenerating response...', isStreaming: true }]);

        handleMessage(userMessage)
    };

    return {
        inputValue,
        setInputValue,
        messages,
        sendMessage,
        recommendGraph,
        refreshThread,
        resubmitMessage,
        changeModel,
    };
}

export default useChat;

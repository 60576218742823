import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faPaperclip } from '@fortawesome/free-solid-svg-icons';
import './dislikeFeedback.scss';

function DislikeFeedback({ onClose }) {
    const [feedback, setFeedback] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);
    const [error, setError] = useState('');
    const [isFileUploadStep, setIsFileUploadStep] = useState(false);
    const [isAttachFileStep, setIsAttachFileStep] = useState(false);
    const [file, setFile] = useState(null);

    const handleSubmitFeedback = () => {
        if (feedback.length < 10) {
            setError('Feedback must be at least 10 characters long.');
            return;
        }

        setIsFileUploadStep(true);
    };

    const handleFileUpload = (event) => {
        setFile(event.target.files[0]);
    };

    const handleFinalSubmit = () => {
        // Final submit logic goes here, including file upload
        console.log("Feedback submitted:", feedback);
        if (file) {
            console.log("File uploaded:", file.name);
        }
        setIsSuccess(true);
        setTimeout(() => {
            setIsSuccess(false);
            onClose();
        }, 3000); // Display success message for 3 seconds
    };

    const handleSkipFileUpload = () => {
        setIsSuccess(true);
        setTimeout(() => {
            setIsSuccess(false);
            onClose();
        }, 2000); // Display success message for 3 seconds
    };

    return (
        <div className="feedback-overlay">
            {isSuccess && (
                <div className="success-overlay">
                    <div className="success-message">
                        <div className="success-circle">&#x2705;</div>
                        <p>Thank you! Your feedback was sent successfully.</p>
                    </div>
                </div>
            )}
            <div className="feedback-window">
                <button className="close-btn" onClick={onClose}>
                    <FontAwesomeIcon icon={faTimes} />
                </button>
                <h2>Submit feedback to Glue</h2>
                {!isFileUploadStep ? (
                    <>
                        <label htmlFor="feedback">What went wrong?</label>
                        <textarea
                            id="feedback"
                            placeholder="Please do not include any personal information"
                            value={feedback}
                            onChange={(e) => setFeedback(e.target.value)}
                        />
                        {error && <p className="error-message">{error}</p>}
                        <div className="button-group">
                            <button className="submit-btn-dislike" onClick={handleSubmitFeedback}>Submit</button>
                            <button className="cancel-btn-dislike" onClick={onClose}>Cancel</button>
                        </div>
                    </>
                ) : !isAttachFileStep ? (
                    <>
                        <p>Would you like to upload a file for fine-tuning the model?</p>
                        <div className="button-group">
                            <button className="submit-btn-dislike" onClick={() => setIsAttachFileStep(true)}>Yes</button>
                            <button className="cancel-btn-dislike" onClick={handleSkipFileUpload}>No, Thanks</button>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="attach-file-section">
                            <p>Please attach your file:</p>
                            <button className="btn-dislike-attach" onClick={() => document.getElementById('file-input').click()}>
                                <FontAwesomeIcon icon={faPaperclip} />
                            </button>
                            <input
                                type="file"
                                id="file-input"
                                style={{ display: 'none' }}
                                onChange={handleFileUpload}
                            />
                        </div>
                        <div className="button-group">
                            <button 
                                className="submit-btn-dislike-attach" 
                                onClick={handleFinalSubmit} 
                                disabled={!file}
                            >
                                Submit
                            </button>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default DislikeFeedback;

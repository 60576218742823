import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './likeFeedback.scss';  // Ensure this import path is correct

function LikeFeedback({ onClose }) {
    const [feedback, setFeedback] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);
    const [error, setError] = useState('');

    const handleSubmit = () => {
        if (feedback.length < 10) {
            setError('Feedback must be at least 10 characters long.');
            return;
        }

        // Submit feedback logic goes here
        console.log("Feedback submitted:", feedback);
        setIsSuccess(true);
        setTimeout(() => {
            setIsSuccess(false);
            onClose();
        }, 2000); // Display success message for 3 seconds
    };

    return (
        <div className="feedback-overlay">
            {isSuccess && (
                <div className="success-overlay">
                    <div className="success-message">
                        <div className="success-circle">&#x2705;</div>
                        <p>Thank you! Your feedback was sent successfully.</p>
                    </div>
                </div>
            )}
            <div className="feedback-window">
                <button className="close-btn" onClick={onClose}>
                    <FontAwesomeIcon icon={faTimes} />
                </button>
                <h2>Submit feedback to Glue</h2>
                <label htmlFor="feedback">What did you like?</label>
                <textarea
                    id="feedback"
                    placeholder="Please do not include any personal information"
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value)}
                />
                {error && <p className="error-message">{error}</p>}
                <div className="button-group">
                    <button className="submit-btn-like" onClick={handleSubmit}>Submit</button>
                    <button className="cancel-btn-like" onClick={onClose}>Cancel</button>
                </div>
            </div>
        </div>
    );
}

export default LikeFeedback;

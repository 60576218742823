// Message.jsx
// This component handles the rendering of individual messages in the chat.


import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { marked } from 'marked';
import botImage from '../assets/pictures/32x32_logo.png'; 

// Override function
const renderer = {
    image(href, title, text) {  
        return `
            <img class="diagram" src="${href}" alt="${text}">

            <!-- The Modal -->
            <div class="modal">
                <span class="close">&times;</span>
                <img class="modal-content">
                <div class="caption"></div>
            </div>`;
    }
  };
  
marked.use({ renderer });

function Message({ msg }) {
    // Event delegation to handle image clicks
    document.body.onclick = function(event) {
        var modal = document.querySelector('.modal')
        if (event.target.classList.contains('diagram')) {
            var modalImg = modal.querySelector('.modal-content');
            var captionText = modal.querySelector('.caption');
            modal.style.display = 'block';
            modalImg.src = event.target.src;
            captionText.innerHTML = event.target.alt;
        }
        if (event.target.classList.contains('close')) {
            modal.style.display = 'none';
        }
    };


    if (msg.sender === "System") {
        return (
            <div>
                <p className="loading-text">{msg.content}</p><p className="loader"></p>
            </div>
        );
    } else {
        return (
            <div className={`message ${msg.sender === 'User' ? 'user' : 'bot'}`}>
                <div className="message-header">
                    <span className={`icon ${msg.sender === 'User' ? 'user-icon' : 'bot-icon'}`}>
                        {msg.sender === 'User' ? <FontAwesomeIcon icon={faUser} /> : <img src={botImage} alt="Bot" className="bot-image" />}
                    </span>
                    <strong>{msg.sender === 'User' ? 'You' : 'Glue GPT'}</strong>
                </div>
                <div className="message-body" dangerouslySetInnerHTML={{ __html: marked(msg.content) }}></div>
            </div>
        );
    };
}

export default Message;

import { useState, useRef, useEffect, useCallback } from 'react';

export const useVoiceInput = (setInputValue, sendMessage) => {
    const [isRecording, setIsRecording] = useState(false);
    const recognitionRef = useRef(null);

    const handleSpeechResult = useCallback((event) => {
        const transcript = Array.from(event.results)
            .map(result => result[0])
            .map(result => result.transcript)
            .join('');

        setInputValue(transcript);
    }, [setInputValue]);

    const handleError = (event) => {
        console.error('Speech recognition error', event.error);
        setIsRecording(false);
    };

    useEffect(() => {
        const initializeRecognition = () => {
            const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
            const recognition = new SpeechRecognition();
            recognition.lang = 'en-UK';
            recognition.interimResults = true;
            recognition.continuous = true;

            recognition.onresult = (event) => handleSpeechResult(event);
            recognition.onerror = (event) => handleError(event);

            recognitionRef.current = recognition;
        };

        initializeRecognition();
    }, [handleSpeechResult]);

    const handleVoiceInput = () => {
        if (isRecording) {
            recognitionRef.current.stop();
            setIsRecording(false);
        } else {
            recognitionRef.current.start();
            setIsRecording(true);
        }
    };

    const stopRecognition = () => {
        if (recognitionRef.current) {
            recognitionRef.current.stop();
            setIsRecording(false);
        }
    };

    return { handleVoiceInput, isRecording, stopRecognition };
};
